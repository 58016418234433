@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Core layout */
html,
body {
  @apply bg-bg-primary text-text-primary font-sans;
}

.layout {
  @apply max-w-screen-sm mx-auto px-8 py-16 sm:py-24 w-full;
}

h1 {
  @apply font-medium;
}

h2 {
  @apply text-base font-normal;
}

h3 {
  @apply text-sm font-normal;
}
.underline {
  text-decoration: underline;
  text-decoration-color: var(--bg-tertiary);
  text-underline-offset: 0.2em;
  text-decoration-thickness: 2.2px;
}

.underline:hover {
  text-decoration-color: var(--border-primary);
}

.overlay-page {
  @apply fixed inset-0 flex justify-center items-center p-6 z-50 bg-bg-overlay backdrop-blur-sm;
}



.contact-form-input {
  @apply w-full px-4 py-3 text-text-primary bg-bg-primary rounded-lg text-sm focus:outline-none border;
}

.images-and-videos-div {
  @apply hidden sm:block shadow-md rounded-md max-h-[500px] max-w-[500px] opacity-100 transition-transform duration-100 ease-in-out;
}

.blog-cover {
  @apply w-full h-64 mb-8 object-cover rounded-md overflow-hidden;
}

.markdown-font-highlight {
  @apply font-normal text-text-primary;
}

.markdown-font-normal {
  @apply font-light text-text-secondary text-lg;
}