@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  /* Basic colors as for layout */
  --white: #fff;
  --black: #000;
  --transparent: transparent;

    /* Brand colors */
    --brand-50: #F6FEFD;
    --brand-100: #F3FEFD;
    --brand-200: #DBFBF8;
    --brand-300: #ABF5EE;
    --brand-400: #7BEFE4;
    --brand-500: #4BEADA;
    --brand-600: #1BE4D0;
    --brand-700: #0dc8b3;
    --brand-800: #0CA39A;
    --brand-900: #097A7C;
    --brand-950: #085F63;
  
    /* Yellow */
    --yellow-25: #FFFBF5;
    --yellow-50: #FFFAF2;
    --yellow-100: #FFF0D7;
    --yellow-200: #FFDBA1;
    --yellow-300: #FFC76B;
    --yellow-400: #FFB236;
    --yellow-500: #FF9E00;
    --yellow-600: #E08A00;
    --yellow-700: #B36C00;
    --yellow-800: #855000;
    --yellow-900: #573300;
    --yellow-950: #3C2400;
  
  
    /* Red colors */
    --red-25: #FFF5F5;
    --red-50: #FEF6F6;
    --red-100: #FCCBCB;
    --red-200: #FCCBCB;
    --red-300: #F9A1A1;
    --red-400: #F68686;
    --red-500: #F97066;
    --red-600: #E53E3E;
    --red-700: #C53030;
    --red-800: #9B2C2C;
    --red-900: #742A2A;
    --red-950: #7F1D1A;
}

.light {
  /* Light colors */

  --gray-25: #FCFCFD;
  --gray-50: #F9FAFB;
  --gray-100: #F2F4F7;
  --gray-200: #EAECF0;
  --gray-300: #D0D5DD;
  --gray-400: #98A2B3;
  --gray-500: #667085;
  --gray-600: #475467;
  --gray-700: #344054;
  --gray-800: #182230;
  --gray-900: #101828;
  --gray-950: #0C111D;
  
  --bg-primary: var(--white); /* layout */
  --bg-primaryHover: var(--gray-50); /* layout */
  --bg-secondary: var(--gray-50); /* layout */
  --bg-secondaryHover: var(--gray-100); /* layout */
  --bg-tertiary: var(--gray-100); /* layout */
  --bg-quaternary: var(--gray-200); /* layout */
  --bg-brand: var(--brand-500); /* layout */
  --bg-overlay: rgba(0, 0, 0, 0.2);
  --bg-success: var(--brand-200);
  --bg-error: var(--red-200);
  --bg-warning: var(--yellow-200);

  --text-primary: var(--gray-900); /* layout */
  --text-secondary: var(--gray-500); /* layout */
  --text-tertiary: var(--gray-400); /* layout */
  --text-quaternary: var(--gray-100); /* layout */
  --text-brand: var(--brand-700);
  --text-placeholder: var(--gray-500); /* layout */
  --text-error: var(--red-600);
  --text-warning: var(--yellow-600);
  --text-success: var(--brand-700);
  --text-disabled: var(--gray-400);

  --border-primary: var(--gray-300); /* layout */
  --border-secondary: var(--gray-200); /* layout */
  --border-tertiary: var(--gray-100); /* layout */
  --border-brand: var(--brand-300); /* layout */
  --border-disabled: var(--gray-300); /* layout */
  --border-success: var(--brand-500);
  --border-error: var(--red-500);
  --border-warning: var(--yellow-500);

  --brand-yellow: var(--yellow-500);

  --breadcrumb-fg: var(--gray-500);
  --breadcrumb-fgActive: var(--gray-900);
  --breadcrumb-fgHover: var(--gray-300);
  --breadcrumb-bgHover: var(--gray-200);

  --tab-bgSelected: rgba(28, 160, 168, 0.08);
  --tab-fgSelected: var(--brand-700);
  --tab-fgUnselected: var(--gray-500);
  --tab-bgHover: var(--gray-200);
  --tab-fgHover: var(--gray-900);

  --button-primary-bg: var(--brand-700);
  --button-primary-border: var(--brand-700);
  --button-primary-fg: var(--white); /* layout */
  --button-primary-bgHover: var(--brand-800);
  --button-primary-borderHover: var(--brand-800);
  --button-primary-fgHover: var(--white); /* layout */
  --button-primary-error: var(--gray-500);
  --button-primary-success: var(--brand-700);

  --button-secondary-bg: var(--white); /* layout */
  --button-secondary-border: var(--gray-300); /* layout */
  --button-secondary-fg: var(--gray-700); /* layout */
  --button-secondary-bgHover: var(--gray-50); /* layout */
  --button-secondary-borderHover: var(--gray-300); /* layout */
  --button-secondary-fgHover: var(--gray-800); /* layout */

  --button-tertiary-bg: var(--transparent); /* layout */
  --button-tertiary-border: var(--transparent); /* layout */
  --button-tertiary-fg: var(--gray-600); /* layout */
  --button-tertiary-bgHover: var(--gray-50); /* layout */
  --button-tertiary-borderHover: var(--transparent); /* layout */
  --button-tertiary-fgHover: var(--gray-700); /* layout */

  --button-success-bg: var(--brand-700);
  --button-success-border: var(--brand-700);
  --button-success-fg: var(--white);
  --button-success-bgHover: var(--brand-800);
  --button-success-borderHover: var(--brand-800);
  --button-success-fgHover: var(--white);

  --button-warning-bg: var(--yellow-500);
  --button-warning-border: var(--yellow-500);
  --button-warning-fg: var(--white);
  --button-warning-bgHover: var(--yellow-600);
  --button-warning-borderHover: var(--yellow-600);
  --button-warning-fgHover: var(--white);

  --button-error-bg: var(--red-600);
  --button-error-border: var(--red-600);
  --button-error-fg: var(--white);
  --button-error-bgHover: var(--red-700);
  --button-error-borderHover: var(--red-700);
  --button-error-fgHover: var(--white);

  --button-disabled-bg: var(--white); /* layout for secondary button */
  --button-disabled-border: var(--gray-200); /* layout for secondary button */
  --button-disabled-fg: var(--gray-400); /* layout for secondary button */

  --breadcrumb-fg: var(--gray-500);
  --breadcrumb-fgActive: var(--gray-900);
  --breadcrumb-fgHover: var(--gray-300);
  --breadcrumb-bgHover: var(--gray-200);

  --shadow-xs: rgba(16, 24, 40, 0.05);
  --shadow-sm-1: rgba(16, 24, 40, 0.1);
  --shadow-sm-2: rgba(16, 24, 40, 0.06);
  --shadow-md-1: rgba(16, 24, 40, 0.1);
  --shadow-md-2: rgba(16, 24, 40, 0.06);
  --shadow-lg-1: rgba(16, 24, 40, 0.1);
  --shadow-lg-2: rgba(16, 24, 40, 0.03);
  --shadow-ring: rgba(21, 195, 177, 0.24);

  --tag-bgPrimary: rgba(28, 160, 168, 0.05);
  --tag-fgPrimary: var(--brand-700);
  --tag-borderPrimary: var(--brand-500);
  --tag-bgSecondary: var(--bg-secondary);
  --tag-fgSecondary: var(--text-secondary);
  --tag-borderSecondary: var(--border-primary);
  --tag-bgTertiary: var(var(--gray-200));
  --tag-fgTertiary: var(--text-tertiary);
  --tag-borderTertiary: var(--border-primary);

  /* App-specific colors */
  --tooltip-bg: var(--gray-100);
  --tooltip-border: var(--gray-300);
}


.dark {
  /* Dark theme variables */

  --gray-25: #FAFAFA;
  --gray-50: #F5F5F6;
  --gray-100: #F0F1F1;
  --gray-200: #ECECED;
  --gray-300: #CECFD2;
  --gray-400: #94969C;
  --gray-500: #85888E;
  --gray-600: #61646C;
  --gray-700: #333741;
  --gray-800: #1F242F;
  --gray-900: #161B26;
  --gray-950: #0C111D;

  --bg-primary: var(--gray-950);
  --bg-primaryHover: var(--gray-800);
  --bg-secondary: var(--gray-900);
  --bg-secondaryHover: var(--gray-800);
  --bg-tertiary: var(--gray-800);
  --bg-quaternary: var(--gray-700);
  --bg-brand: var(--brand-600);
  --bg-overlay: rgba(0, 0, 0, 0.4);
  --bg-success: var(--brand-950);
  --bg-error: var(--red-950);
  --bg-warning: var(--yellow-950);

  --text-primary: var(--gray-50);
  --text-secondary: var(--gray-300);
  --text-tertiary: var(--gray-400);
  --text-quaternary: var(--gray-400);
  --text-brand: var(--brand-600);
  --text-placeholder: var(--gray-500);
  --text-error: var(--red-500);
  --text-warning: var(--yellow-500);
  --text-success: var(--brand-600);
  --text-disabled: var(--gray-700);

  --border-primary: var(--gray-700);
  --border-secondary: var(--gray-800);
  --border-tertiary: var(--gray-800);
  --border-brand: var(--brand-600);
  --border-disabled: var(--gray-700);
  --border-success: var(--brand-950);
  --border-error: var(--red-950);
  --border-warning: var(--yellow-950);

  --brand-yellow: var(--yellow-500);

  --breadcrumb-fg: var(--gray-500);
  --breadcrumb-fgActive: var(--gray-50);
  --breadcrumb-fgHover: var(--gray-300);
  --breadcrumb-bgHover: var(--gray-700);

  --tab-bgSelected: rgba(28, 160, 168, 0.1);
  --tab-fgSelected: var(--brand-700);
  --tab-fgUnselected: var(--gray-400);
  --tab-bgHover: var(--gray-900);
  --tab-fgHover: var(--gray-50);

  --button-primary-bg: var(--brand-900);
  --button-primary-border: var(--brand-900);
  --button-primary-fg: var(--white);
  --button-primary-bgHover: var(--brand-800);
  --button-primary-borderHover: var(--brand-800);
  --button-primary-fgHover: var(--white);
  --button-primary-error: var(--gray-500);
  --button-primary-success: var(--brand-700);

  --button-secondary-bg: var(--gray-900);
  --button-secondary-border: var(--gray-700);
  --button-secondary-fg: var(--gray-100);
  --button-secondary-bgHover: var(--gray-800);
  --button-secondary-borderHover: var(--gray-800);
  --button-secondary-fgHover: var(--gray-200);

  --button-tertiary-bg: var(--transparent);
  --button-tertiary-border: var(--transparent);
  --button-tertiary-fg: var(--gray-100);
  --button-tertiary-bgHover: var(--gray-800);
  --button-tertiary-borderHover: var(--transparent);
  --button-tertiary-fgHover: var(--gray-200);

  --button-success-bg: var(--brand-800);
  --button-success-border: var(--brand-800);
  --button-success-fg: var(--white);
  --button-success-bgHover: var(--brand-900);
  --button-success-borderHover: var(--brand-900);
  --button-success-fgHover: var(--white);

  --button-warning-bg: var(--yellow-500);
  --button-warning-border: var(--yellow-500);
  --button-warning-fg: var(--white);
  --button-warning-bgHover: var(--yellow-600);
  --button-warning-borderHover: var(--yellow-600);
  --button-warning-fgHover: var(--white);

  --button-error-bg: var(--red-600);
  --button-error-border: var(--red-600);
  --button-error-fg: var(--white);
  --button-error-bgHover: var(--red-700);
  --button-error-borderHover: var(--red-700);
  --button-error-fgHover: var(--white);

  --button-disabled-bg: var(--gray-950);
  --button-disabled-border: var(--gray-800);
  --button-disabled-fg: var(--gray-700);

  --tooltip-bg: var(--gray-800);
  --tooltip-border: var(--gray-900);

  --breadcrumbs-fg: var(--gray-400);
  --breadcrumbs-fgActive: var(--gray-50);
  --breadcrumbs-fgHover: var(--gray-300);
  --breadcrumbs-bgHover: var(--gray-700);

  --shadow-xs: rgba(0, 0, 0, 0.05);
  --shadow-sm-1: rgba(0, 0, 0, 0.1);
  --shadow-sm-2: rgba(0, 0, 0, 0.1);
  --shadow-md-1: rgba(0, 0, 0, 0.4);
  --shadow-md-2: rgba(0, 0, 0, 0.3);
  --shadow-lg-1: rgba(0, 0, 0, 0.4);
  --shadow-lg-2: rgba(0, 0, 0, 0.2);
  --shadow-ring: rgba(21, 195, 177, 0.24);

  --tag-bgPrimary: rgba(28, 160, 168, 0.05);
  --tag-fgPrimary: var(--brand-700);
  --tag-borderPrimary: var(--brand-950);
  --tag-bgSecondary: var(--bg-secondary);
  --tag-fgSecondary: var(--text-secondary);
  --tag-borderSecondary: var(--border-primary);
  --tag-bgTertiary: var(--gray-700);
  --tag-fgTertiary: var(--text-tertiary);
  --tag-borderTertiary: var(--border-secondary);

  /* App-specific colors */
  --tooltip-bg: var(--gray-800);
  --tooltip-border: var(--gray-900);
}

.dark-dusk {
  /* Dusk theme variables */

  --gray-25: #F5F8FA;
  --gray-50: #E1E8ED;
  --gray-100: #C4D4E0;
  --gray-200: #A7C0D3;
  --gray-300: #8AACC6;
  --gray-400: #6D98B9;
  --gray-500: #5074A2;
  --gray-600: #3A5C7A;
  --gray-700: #2A4A5F;
  --gray-800: #1A3844;
  --gray-900: #122A33;
  --gray-950: #0A1C22;

  --bg-primary: var(--gray-950);
  --bg-primaryHover: var(--gray-800);
  --bg-secondary: var(--gray-900);
  --bg-secondaryHover: var(--gray-800);
  --bg-tertiary: var(--gray-800);
  --bg-quaternary: var(--gray-700);
  --bg-brand: var(--brand-600);
  --bg-overlay: rgba(0, 0, 0, 0.5);
  --bg-success: var(--brand-950);
  --bg-error: var(--red-950);
  --bg-warning: var(--yellow-950);

  --text-primary: var(--gray-50);
  --text-secondary: var(--gray-300);
  --text-tertiary: var(--gray-400);
  --text-quaternary: var(--gray-400);
  --text-brand: var(--brand-600);
  --text-placeholder: var(--gray-500);
  --text-error: var(--red-500);
  --text-warning: var(--yellow-500);
  --text-success: var(--brand-600);
  --text-disabled: var(--gray-700);

  --border-primary: var(--gray-700);
  --border-secondary: var(--gray-800);
  --border-tertiary: var(--gray-800);
  --border-brand: var(--brand-600);
  --border-disabled: var(--gray-700);
  --border-success: var(--brand-950);
  --border-error: var(--red-950);
  --border-warning: var(--yellow-950);

  --brand-yellow: var(--yellow-500);

  --breadcrumb-fg: var(--gray-500);
  --breadcrumb-fgActive: var(--gray-50);
  --breadcrumb-fgHover: var(--gray-300);
  --breadcrumb-bgHover: var(--gray-700);

  --tab-bgSelected: rgba(28, 160, 168, 0.1);
  --tab-fgSelected: var(--brand-700);
  --tab-fgUnselected: var(--gray-400);
  --tab-bgHover: var(--gray-900);
  --tab-fgHover: var(--gray-50);

  --button-primary-bg: var(--brand-900);
  --button-primary-border: var(--brand-900);
  --button-primary-fg: var(--white);
  --button-primary-bgHover: var(--brand-800);
  --button-primary-borderHover: var(--brand-800);
  --button-primary-fgHover: var(--white);
  --button-primary-error: var(--gray-500);
  --button-primary-success: var(--brand-700);

  --button-secondary-bg: var(--gray-900);
  --button-secondary-border: var(--gray-700);
  --button-secondary-fg: var(--gray-100);
  --button-secondary-bgHover: var(--gray-800);
  --button-secondary-borderHover: var(--gray-800);
  --button-secondary-fgHover: var(--gray-200);

  --button-tertiary-bg: var(--transparent);
  --button-tertiary-border: var(--transparent);
  --button-tertiary-fg: var(--gray-100);
  --button-tertiary-bgHover: var(--gray-800);
  --button-tertiary-borderHover: var(--transparent);
  --button-tertiary-fgHover: var(--gray-200);

  --button-success-bg: var(--brand-800);
  --button-success-border: var(--brand-800);
  --button-success-fg: var(--white);
  --button-success-bgHover: var(--brand-900);
  --button-success-borderHover: var(--brand-900);
  --button-success-fgHover: var(--white);

  --button-warning-bg: var(--yellow-500);
  --button-warning-border: var(--yellow-500);
  --button-warning-fg: var(--white);
  --button-warning-bgHover: var(--yellow-600);
  --button-warning-borderHover: var(--yellow-600);
  --button-warning-fgHover: var(--white);

  --button-error-bg: var(--red-600);
  --button-error-border: var(--red-600);
  --button-error-fg: var(--white);
  --button-error-bgHover: var(--red-700);
  --button-error-borderHover: var(--red-700);
  --button-error-fgHover: var(--white);

  --button-disabled-bg: var(--gray-950);
  --button-disabled-border: var(--gray-800);
  --button-disabled-fg: var(--gray-700);

  --tooltip-bg: var(--gray-800);
  --tooltip-border: var(--gray-900);

  --breadcrumbs-fg: var(--gray-400);
  --breadcrumbs-fgActive: var(--gray-50);
  --breadcrumbs-fgHover: var(--gray-300);
  --breadcrumbs-bgHover: var(--gray-700);

  --shadow-xs: rgba(0, 0, 0, 0.05);
  --shadow-sm-1: rgba(0, 0, 0, 0.1);
  --shadow-sm-2: rgba(0, 0, 0, 0.1);
  --shadow-md-1: rgba(0, 0, 0, 0.4);
  --shadow-md-2: rgba(0, 0, 0, 0.3);
  --shadow-lg-1: rgba(0, 0, 0, 0.4);
  --shadow-lg-2: rgba(0, 0, 0, 0.2);
  --shadow-ring: rgba(21, 195, 177, 0.24);

  --tag-bgPrimary: rgba(28, 160, 168, 0.05);
  --tag-fgPrimary: var(--brand-700);
  --tag-borderPrimary: var(--brand-950);
  --tag-bgSecondary: var(--bg-secondary);
  --tag-fgSecondary: var(--text-secondary);
  --tag-borderSecondary: var(--border-primary);
  --tag-bgTertiary: var(--gray-700);
  --tag-fgTertiary: var(--text-tertiary);
  --tag-borderTertiary: var(--border-secondary);

  /* App-specific colors */
  --tooltip-bg: var(--gray-800);
  --tooltip-border: var(--gray-900);
}
